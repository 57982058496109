.App {
    background-color: #222831;
    color: #EEEEEE;
  }

body {
    overflow: hidden;
}

* {
    scroll-behavior: smooth;
}

.fluid-container {
    margin: 0;
    padding: 0;
}
  
.name {
font-size: calc(30px + 2vmin);
}

.title {
font-size: calc(10px + 2vmin);
font-style: normal;
opacity: 100%;
}

.statement {
font-size: calc(10px + 2vmin);
font-style: italic;
opacity: 70%;
}

.about-content {
display:block;
position:absolute;
right:0;
width:50%;
overflow: hidden;
overflow-y: scroll;
font-size: calc(5px + 2vmin);
background-color: #222831;
height: 100vh;
}

.about {
    padding: 5%;
}

.experience {
    padding: 5%;
}

.main-content {
padding: 5%;
width: 50%;
display:flex;
flex-wrap: wrap;
justify-content:flex-start;
position: absolute;
overflow: hidden;
background-color: #222831;
height: 100vh;
}

.introduction {
    height: auto;
}

a {
color: inherit;
padding-right: 25px;
}

.hash-link {
    font-size: calc(5px + 2vmin);
    text-decoration: none;
    position:relative;
    display: inline-block;
}

.hash-link:hover,.resume-link:hover {
    color:#00ADB5;
}

.hash-link::after, .resume-link::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #00ADB5;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

.hash-link:hover::after, .resume-link:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
.resume-link {
    font-size: calc(10px + 2vmin);
    text-decoration: none;
    position:relative;
    display: inline-block;
}

.resume-link-div {
    padding-bottom: 20px;
}

h1 {
    font-size: calc(30px + 2vmin);
    color: #00ADB5;
}

h2 {
    font-size: calc(20px + 2vmin);
    color: #00ADB5;
}

h3 {
    font-style: italic;
    opacity: 50%;
    font-size: calc(5px + 2vmin);
}
  
.icons  {
    padding: 0%;
}
  
.badge-div{
    padding-bottom: 5px;
}

.badge-div span {
    margin-right: 5px;
    margin-bottom: 5px;
}

.hash-link {
    scroll-behavior: smooth;
}